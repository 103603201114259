<template> 
  <div>
    <div v-if="!viewBtn">
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">Application - {{total}}</a></h1>
      <form class="form-inline">
        <input v-model="GlobalSearch" v-if="!isadd" class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
        <button @click="GlobalSearchAction" v-if="!isadd" class="btn btn-primary" style="background-color:#3297fa" type="button">Search</button>
        <button @click="GlobalSearchClear" v-if="(GlobalSearch != '' && !isadd)" class="btn btn-danger" type="button">Clear</button>        
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="!isadd" @click="AddNewApp();" >ADD New Application</button>
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="(!isadd && SelectedItems.length == 0)" @click="exportExcel" >Export Excel</button>
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="(!isadd && SelectedItems.length > 0)" @click="downloadFile()" >Export Excel</button>
        <button type="button" class="btn btn-danger" v-if="!isadd && SelectedItems.length > 0" @click="Bulkdel" >Delete</button>
        <!-- <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="Mode=='UPDATE'" @click="download" >Download Resume</button> -->
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="Mode=='UPDATE' && UserID" @click="actityLog=true">Activity Log</button>
        <button type="button" class="btn btn-danger" v-if="isadd" @click="isadd=false; Mode='NEW';actityLog=false; reset()" >Back</button> <!--<i class="far fa-times-circle"></i>-->
      </form>      
    </nav>
  </div>
    <div class="grid" style="margin-top:60px" v-if="!isadd">
      <Grid
        ref="grid"    
        :data-items="gridData"
        scrollable: true
        :pageable = "true"
        :take="dataState.take"
        :skip="dataState.skip"
        :sort="dataState.sort"
        :total="total"
        :loader="loader"
        :selected-field="selectedField"
        @datastatechange="dataStateChange"
        @selectionchange="onSelectionChange"
        :row-render="cellFunction"
        :columns="columns"
        :filter="dataState.filter"
        :sortable="true"   
        @headerselectionchange="onHeaderSelectionChange"
    >
      <template v-slot:myTemplate="{ props }">
          <custom
            :column="props.column"
            :filterable="props.filterable"
            :filter="props.filter"
            :sortable="props.sortable"
            :sort="props.sort"
            :columns="columns"
            @sortchange="(e) => props.onSortchange(e)"
            @filterchange="(e) => props.onFilterchange(e)"
            @closemenu="(e) => props.onClosemenu(e)"
            @contentfocus="(e) => props.onContentfocus(e)"
            @columnssubmit="onColumnsSubmit"          
          />
      </template>
      <template v-slot:myTemplate1="{props}">
        <actionbtn :data-item="props.dataItem"  :isapprove="true"
          @view="view"
          @edit="edit"
          @approve="approve"
          @remove="del"
          @download="download"/>
      </template>
    </Grid>
    </div>
  </div>
  <div class="card shadow" v-if="isadd">
    <div class="card-body">
      <div>
        <form>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Name</label>
              <input type="text" v-model="Name" class="form-control" name="Name" placeholder="Enter Name">      
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Mail ID</label>
              <input type="text" v-model="MailId" class="form-control" name="MailId" placeholder="Enter Mail ID">      
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Gender</label>
            <div>
              <dropdownlist
              :style="{padding:'5px'}"
              :data-items='GenderList'
              v-model="Gender"
              >
              </dropdownlist>
            </div>            
            </div>
          </div>
          <div class="form-row">            
            <div class="form-group col-md-4">
              <label for="inputEmail4">Phone Number</label>
              <input type="text" v-model="PhoneNumber" class="form-control" name="PhoneNumber" placeholder="Enter Phone Number">  
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Work Location</label>              
              <div>
              <dropdownlist v-if="!IsWorklocationOthers"
              :style="{padding:'5px'}"
              :filterable="true"
              @filterchange="filterChange"
              :data-items='WorkLocationList'
              v-model="WorkLocation"
              @change="workLocchange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsWorklocationOthers">
                <input type="text" v-model="WorkLocation" placeholder="Enter Work Location" class="form-control" name="Name">
                <button
                type="reset"
                class="is-active"
                @click="WorkLocation='', IsWorklocationOthers=false"
              >
                x
              </button>
              </div>
            </div>
            </div>             
            
            <div class="form-group col-md-4">
              <label for="inputEmail4">Qualification</label>              
              <div>
              <dropdownlist v-if="!IsQualification"
              :style="{padding:'5px'}"
              :filterable="true"
              @filterchange="QualfilterChange"
              :data-items='QualificationList'
              v-model="Qualification"
              @change="QualChange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsQualification">
              <div>
                <input type="text" v-model="Qualification" placeholder="Enter Qualification" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="Qualification='', IsQualification=false"
              >
                x
              </button>
            </div>            
            </div>
            </div>            
            </div>               
            
          </div> 
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Job Category</label>
              <div>
              <dropdownlist v-if="!IsJobCategory"
              :style="{padding:'5px'}"
              :data-items='JobCategoryList'
              :filterable="true"
              @filterchange="JobfilterChange"
              v-model="JobCategory"
              @change="JobCategoryChange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsJobCategory">
              <div>
                <input type="text" v-model="JobCategory" placeholder="Enter Job Category" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="JobCategory='',  Designation='', IsJobCategory=false"
              >
                x
              </button>
            </div>
            </div>
            </div>
            </div>
            
             <div class="form-group col-md-4">
              <label for="inputEmail4">Designation</label>
              <div>
              <dropdownlist v-if="!IsJobCategory"
              :style="{padding:'5px'}"
              :data-items='DesignationList'
              v-model="Designation"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsJobCategory">
              <div>
                <input type="text" v-model="Designation" placeholder="Enter Designation" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="JobCategory='', Designation='', IsJobCategory=false"
              >
                x
              </button>
            </div>
            </div>
            </div>
            </div>
            
             <div class="form-group col-md-4">
              <label for="inputEmail4">Experience</label>
              <div>
              <dropdownlist v-if="!IsExperience"
              :style="{padding:'5px'}"
              :data-items='ExperienceList'
              v-model="Experience"
              @change="ExepChange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsExperience">
              <div>
                <input type="text" v-model="Experience" placeholder="Enter Experience" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="Experience='', IsExperience=false"
              >
                x
              </button>
            </div>            
            </div>
            </div>
            </div>
            
                    
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Date of Birth</label>
              <!-- <datepicker 
              :style="{padding:'5px'}"
              :value="DOB"
              :format="'dd-MMM-yyyy'"
              @change="DOBhandleChange"
             />   -->
             <mydatepicker @myfunction="setdob"  :value="DOB" v-model="DOB" :id="'Datepicker'" />      
            </div>            
            <div class="form-group col-md-4" >
              <label for="inputEmail4">Preferred Location</label>
              <multiselect
              :style="{padding:'5px'}"
              :filterable="true"
              @filterchange="PreffilterChange"
              :data-items='PreferredLocationList'
              v-model="PreferredLocation"
              >
              </multiselect>
            </div>
            <div class="form-group col-md-4">              
              <label for="inputEmail4">Upload Resume</label>
              <div style="padding:5px;">
                <input @change="handlefile"  type="file">
              </div>              
            </div>
          </div> 
          <div class="form-row">
            <div class="form-group col-md-4">              
            <label for="inputEmail4">CTC Range</label>
            <dropdownlist v-if="!IsCtcRange"
            :style="{padding:'5px'}"
            :data-items='CtcRangeList'
            v-model="CtcRange"
            @change="ctcchange"
            >
            </dropdownlist>
            <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsCtcRange">              
            <input type="text" v-model="CtcRange" placeholder="Enter Ctc Range"  class="form-control" name="JobAppliedfor">
            <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="CtcRange='', IsCtcRange=false"
              >
                x
              </button>
            </div>              
            </div>            
            <div class="form-group col-md-4">
                <label for="inputEmail4">Technical Skills</label>
                <input type="text" v-model="TechnicalSkillsComma" class="form-control" name="JobAppliedfor" placeholder="Enter Comma For Multiple Technical Skills"> 
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Job Applied For</label>
              <input type="text" v-model="JobAppliedfor" class="form-control" name="JobAppliedfor" placeholder="Enter Job Applied For">  
            </div>  
          </div>          
          <div class="form-row" v-if = "Experience != 'Fresher'">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Employment History</label>
              <table class="table table-striped table-sm table-bordered">
              <thead>
                <tr>
                  <th class="form-control-sm">Organization Name</th>
                  <th class="form-control-sm">Start Date</th>
                  <th class="form-control-sm">End Date</th>
                  <th class="form-control-sm">Designation</th>
                  <th class="form-control-sm text-center">Present</th>
                  <th class="form-control-sm text-center">Action Item</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in EmploymentHistory" v-bind:key="i">
                  <td>
                    <input type="text" v-model="d.PreOrganizationName" class="form-control" name="JobAppliedfor" placeholder="Previous Organization Name"> 
                  </td>
                  <td>
                    <!-- <input type="date" onkeydown="return false"  id="datemin" name="datemin" v-model="d.StartDate"
                    style="padding:5px">      -->
                    <mydatepicker  :id="'Datepickerstart'+i"  @myfunction="e => d.StartDate=e"   :value="d.StartDate" v-model="d.StartDate" />               
                  </td>
                  <td>
                    <!-- <input type="date" onkeydown="return false"  id="datemin" name="datemin" v-model="d.EndDate"
                    style="padding:5px"> -->
                             <mydatepicker  :id="'Datepickerend'+i"  @myfunction="e => d.EndDate=e" :value="d.EndDate" v-model="d.EndDate" />   
                  </td>
                  <td>
                    <input type="text" v-model="d.PreDesignation" class="form-control" name="JobAppliedfor" placeholder="Previous Designation"> 
                  </td>
                  <td class="text-center"> 
                    <input v-model="d.Present" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="RemoveRange(i)"
                      v-if="EmploymentHistory.length > 1"                      
                      >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="AddRange(i)"
                      v-if="i == EmploymentHistory.length - 1"
                      >
                      <i class="fa fa-plus"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              </table>  
            </div>           
          </div>
          <div class="form-row" v-if="Mode=='UPDATE'">
            <div class="form-group col-md-3">
              <label for="inputPassword4">Is Placed</label>
              <div >
                <label class="switch " style="float: left;">
                <input type="checkbox" v-model="Isplaced"  class="success">
                <span class="slider"></span>
                </label>
            </div>
            </div>
            <div class="form-group col-md-9">
              <label for="inputEmail4">Candidate Comments (If any)</label>           
              <textarea class="form-control" v-model="Candidatecommands" disabled  id="exampleFormControlTextarea1" rows="1"></textarea>
            </div>  
          </div>           
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Notes (If any)</label>           
              <textarea class="form-control" v-model="Notes" id="exampleFormControlTextarea1" rows="1"></textarea>
            </div>           
          </div>
          <div class="form-row" v-if='Mode != "NEW"'>
            <div class="form-group col-md-12">
              <label for="inputEmail4">Notes History</label> 
              <table class="table table-striped table-sm table-bordered">
                <tr>
                  <th class="form-control-sm">Notes</th>
                  <th class="form-control-sm">Modified By</th>
                  <th class="form-control-sm">Date</th>
                </tr>
                <tr v-for="n in NotesHistory" :key="n.ID" style="color:black">
                  <td>{{n.Notes}}</td>
                  <td>{{n.User}}</td>
                  <td>{{formateDate(n.ModifiedOn)}}</td>
                  <td style="text-align: center;width:20px"> <button class="btn btn-sm btn-danger" type="button" @click="delNotes(n)"><i class="fas fa-trash"></i></button></td>
                </tr> 
              </table>
            </div>           
          </div> 
          <div v-if="PageLoading">
            <div class="col-12" style="text-align: right;">      
              <div class="loader--ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div v-if="!PageLoading">
            <button type="button" class="btn btn-default" style="float: left;" v-if="isadd && Mode=='NEW'" @click="save('saveAdd')" >Save & add</button> 
            <button type="button" class="btn btn-default" style="float: right;margin-left:10px" @click="save('saveAlone')" >Save</button> 
            <button type="button" class="btn btn-success" style="float: right;" v-if="isadd && Mode=='NEW'" @click="save('saveClose')" >Save & close</button>
            <button type="button" class="btn btn-default" style="float: right;" v-if="isadd && Mode=='UPDATE' && UserID" @click="save('saveClose')" >Save & close</button>
          </div>
        </form>
      </div>
    </div>      
  </div> 
      
  <div v-if="isadd && Mode=='UPDATE' && actityLog">
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div>
                <slot name="header">
                  <div class="top-navbar">
                    <nav class="navbar navbar-light justify-content-between">
                      <h1><a class="navbar-brand">Activity Log</a></h1>
                      <form class="form-inline">
                        <button type="button" class="btn btn-danger" @click="actityLog=false" ><i class="far fa-times-circle"></i></button>                        
                      </form>
                    </nav>
                  </div>
                </slot>
              </div>

              <div class="modal-body">
                <slot name="body">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <table class="table table-striped table-sm table-bordered">
                          <tr>
                            <th class="form-control-sm">User Name</th>
                            <th class="form-control-sm">Module</th>
                            <th class="form-control-sm">Log</th>
                            <th class="form-control-sm">Date</th>
                          </tr>
                          <tr v-for="n in ActivtyGrid" :key="n.ID" style="color:black">
                            <td>{{n.username}}</td>
                            <td>{{n.module}}</td>
                            <td>{{n.log}}</td>
                            <td>{{formateDate(n.createdon)}}</td>
                          </tr> 
                        </table>
                      </div>           
                    </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
  <div v-show="viewBtn">
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
        <h1><a class="navbar-brand">View Application</a></h1>
        <form class="form-inline">
          <select v-model="cstatus" style="margin-right: 10px;
    width: 30%;" class="form-control2" @change="updatestatus()">
            <option v-for="s in statusmaster" :key="s.text" :value="s.text">{{ s.text }}</option>
          </select>
          <button type="button" class="btn btn-success" @click="DownloadFilledApp">Download Filled PDF</button>                      
          <!-- <button type="button" class="btn btn-primary" @click="download({n:Filepath})">Download Resume</button>                       -->
          <button type="button" class="btn btn-danger" @click="viewBtn=false; reset()" ><i class="far fa-times-circle"></i></button>  
        </form>
      </nav>
    </div>
    <div class="card shadow">
      <div class="card-body">
        <div id="element-to-convert" class="yui-t7">
          <div id="inner">
                <h3 v-if="loadingpdf">loading...</h3>
                <iframe id="pdfIframe" src="" frameborder="0" style="width: 100%;height: 600px;"></iframe>
                </div>
        </div>
        
      </div>
    </div>
  </div>
    <transition class="error">
      <div id="maxalert" v-if="maxalert"> {{max}} <i class="far fa-times-circle" @click="maxalert=false"></i></div>
    </transition>    
  </div>
</template>

<script>
import helper from "../helper.js"
import { Grid, filterGroupByField } from '@progress/kendo-vue-grid';
import { MultiSelect } from '@progress/kendo-vue-dropdowns';
import ColumnMenu from './ColumnMenu';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
// import { DatePicker } from '@progress/kendo-vue-dateinputs';
import '@progress/kendo-theme-default/dist/all.css';
import CommandCell from './CommandCellDown';
import swal from 'sweetalert';
import { saveExcel } from '@progress/kendo-vue-excel-export';
//import { provideIntlService } from '@progress/kendo-vue-intl';
import html2pdf from "html2pdf.js";
import { filterBy } from '@progress/kendo-data-query';
import Mydatepicker from '../components/Mydatepicker.vue'
import moment from "moment"
//import exportFromJSON from "export-from-json";


export default {
  components: {
    Grid,
    Mydatepicker,
    custom: ColumnMenu,
    'dropdownlist': DropDownList,
    // 'datepicker': DatePicker,
    'multiselect': MultiSelect,
    'actionbtn': CommandCell,
    
  },
  data() {
    return {
      Isplaced:false,
      viewBtn:false,
      UserID:false,
      actityLog : false,
      GlobalSearch:"",
      PageLoading:false,
      gridData: [],
      ActivtyGrid: [],
      activityList : [],
      activityTotal:0,
      sort: null,
      skip: 0,
      take: 10,
      total: 0,
      Name: "",
      MailId: "",
      mobilenumber: "",
      Age:"",
      otp:"",
      WorkLocation: "",
      Qualification: "",
      JobCategory: "",
      Designation: "",
      Experience: "",
      JobAppliedfor: "",
      Gender: "",
      IsActive: true,
      maxalert: false,
      ApplicationStatus:"",
      username:"",
      max: 0,
      Mode: "NEW",
      Qualifying: [],
      Particular:"SSLC",
      yearpassed:"",
      regno:"",
      markscored:"",
      totalmark:"",
      markpre:"",
      Institution:"",
      Medium:"",
      Attempts:"",
      DOB: "",
      MotherTongue:"Tamil",
      Community:"",
      Caste:"",
      WhatsAppNo:"",
      AadharNo:"",
      Religion:"",
      CommunicationAddress:"",
      Post:"",
      Taluk:"",
      District:"",
      Pincode:"",
      FatherName:"",
      Occupation:"",
      MotherName:"",
      AnnualIncome:"",
      Nationality:"Indian",
      State:"Tamilnadu",
      currentdate:"",
      today:"",
      Place:"",
      Signatureby:"",
      GuardianName:"",
      
      SSLCTamilq1:'',
      SSLCTamilq2:'',
      SSLCTamilq3:'',
      SSLCTamilMY:'',
      SSLCEnglishq1:'',
      SSLCEnglishq2:'',
      SSLCEnglishq3:'',
      SSLCEnglishMY:'',
      SSLCMathq1:'',
      SSLCMathq2:'',
      SSLCMathq3:'',
      SSLCMathMY:'',
      SSLCScienceq1:'',
      SSLCScienceq2:'',
      SSLCScienceq3:'',
      SSLCScienceMY:'',
      SSLCSocialsq1:'',
      SSLCSocialsq2:'',
      SSLCSocialsq3:'',
      SSLCSocialsMY:'',
      HSCTamilq1:'',
      HSCTamilq2:'',
      HSCTamilq3:'',
      HSCTamilMY:'',
      HSCEnglishq1:'',
      HSCEnglishq2:'',
      HSCEnglishq3:'',
      HSCEnglishMY:'',
      HSCPhysicsq1:'',
      HSCPhysicsq2:'',
      HSCPhysicsq3:'',
      HSCPhysicsMY:'',
      HSCChemistryq1:'',
      HSCChemistryq2:'',
      HSCChemistryq3:'',
      HSCChemistryMY:'',
      HSCMathq1:'',
      HSCMathq2:'',
      HSCMathq3:'',
      HSCMathMY:'',
      HSCBioq1:'',
      HSCBioq2:'',
      HSCBioq3:'',
      HSCBioMY:'',
      NameVocational:'',
      HSCtq1:'',
      HSCtq2:'',
      HSCt3:'',
      HSCtMY:'',
      HSCPractical1q1:'',
      HSCPractical1q2:'',
      HSCPractical13:'',
      HSCPractical1MY:'',
      HSCPractical2q1:'',
      HSCPractical2q2:'',
      HSCPractical23:'',
      HSCPractical2MY:'',

      ExtraCurricular :[],
      SportsGames:"",
      National:"",
      ECState:"",
      Devision:"",
      ECDistrict:"",

      PhysicalIdentification1:"",
PhysicalIdentification2:"",
Transport:"",
admittingyear:"",
Branch:"",
Route:"",
Stage:"",
BoardingPoint:"",
Hostel:"",
BloodGroup:"",
exserviceman:"",
exservicemanNo:"",
exservicemanRank:"",
firstgraduate:"",
singleChild:"",
differentlyabled:"",
NSSYRC:"",

docname:"Applicant Photo",
Docs:[],
ApplicantSign:"",
ParentSign:"",
applicationID:"",
profilecompleted:0,
      isadd:false,
      file:"",
      FileName : "",
      columns: [
        { field: 'selected',
         width: '50px',
         headerSelectionValue: this.areAllSelected ,
        //  locked:true
        },
        {
          title: 'Name',
          // locked:true,
          width : "150px",
          field: 'Name',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Citizenship',
          width : "150px",
          field: 'Citizenship',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Status',
          width : "120px",
          field: 'cutomestatus',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'SubmitedDate',
          width : "120px",
          field: 'SubmitedDate',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Country Code',
          width : "150px",
          field: 'countryCode',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
         {
          title: 'Phone Number',
          // locked:true,
          width : "180px",
          field: 'Telephonem',
          filter: 'numeric',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Email',
          width : "150px",
          field: 'emailid',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Referred By',
          width : "120px",
          field: 'Referred',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        // {
        //   title: 'Application No',
        //   width : "120px",
        //   field: 'applicationID',
        //   filter: 'numeric',
        //   columnMenu: 'myTemplate',
        //   headerClassName: 'customMenu',
        // },
        
        // {
        //   title: 'Known By',
        //   width : "120px",
        //   field: 'Knownby',
        //   filter: 'text',
        //   columnMenu: 'myTemplate',
        //   headerClassName: 'customMenu',
        // },
        
       
        {
          title: 'Gender',
          width : "120px",
          field: 'Gender',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Program',
          width : "120px",
          field: 'selectedSubProgram',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },        
        {
          title: 'Family Name',
          width : "120px",
          field: 'familyName',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },     
        { cell: 'myTemplate1', width: '220px'}
        
      ],      
      dataState: {
        take: 10,
        skip: 0,
      },      
      CtcRangeList : [
      "Other",
      "0 lakh - 0.6 lakh",
      "0.6 lakh - 1.2 lakh",
      "1.2 lakh - 2.4 lakh",
      "2.4 lakh - 3.6 lakh",
      "3.6 lakh - 4.8 lakh",
      "4.8 lakh - 6 lakh",
      "6 lakh - 7 lakh",
      "7 lakh - 8 lakh",
      "8 lakh - 9 lakh",
      "9 lakh - 10 lakh",
      "10 lakh - 12 lakh",
      "12 lakh - 15 lakh",
      "15 lakh - 18 lakh",
      "18 lakh - 20 lakh",
      "20 lakh - 25 lakh",
      "25 lakh - 30 lakh",
      "30 lakh+"
      ],
      CtcRange : "",
      EmploymentHistory:[],      
      selectedField: 'selected',
      SelectedItems:[],
      GlobalFilter : [],
      GlobalFilterTemp : [],
      TimeNow : new Date(),
      TechSkillCnt : 0,
      arrTechData : [],
      failFlag : false,
      errorFlag : false,
      AllList : [],
      IsWorklocationOthers:false,
      IsCtcRange:false,
      IsQualification:false,
      IsExperience:false,
      IsJobCategory:false,
      statusmaster:[],
      cstatus:"",
    };
  },
   created: function () {     
      this.getData();
      this.branchList();
     // this.genderList();
      // this.qualificationList();
      // this.experienceList();
      // this.workLocationList();
      // this.designationList(); 
  },
  mounted(){
    var userDetails = sessionStorage.getItem("sessionDetails");
    if (userDetails===null || userDetails==""){
       window.location.href = '#/login';
    } else {
      var t = JSON.parse(userDetails) //atob(udata)
      if(t.userID == "ceo@demo.com"){
        this.UserID = true
      }
      this.SessionID = t.SessionId
      if(this.SessionID == "" || this.SessionID == undefined || this.SessionID == null){
        window.location.href = '#/login';
      }
    } 
  },
  
  watch:{
  

  },  
  
  methods: {
    branchList(){
      var self = this;
      const article = {
        "IsActive":true
      };
      this.axios.post(helper.SERVICEURL + "Statustitle/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              if(x.IsActive){
                  let temp = {text:x.Statustitle,value:x.Statustitle}
                  self.statusmaster.push(temp)
                }
            })
          }
        });
    },
    methods: {
  statusCell(h, cellProps) {
    const dataItem = cellProps.dataItem;
    const field = cellProps.field;

    // Check if dataItem and field are defined
    if (!dataItem || !field) {
      
      return h('select', {
      domProps: {
        value: 'submitted'
      },
      on: {
        change: (event) => {
          // Update the value in the data source
          dataItem[field] = event.target.value;
          this.onStatusChange(dataItem);
        }
      }
    }, 
    this.statusOptions.map(option =>
      h('option', {
        domProps: {
          value: option.value,
          innerHTML: option.text,
          selected: option.value === dataItem[field]
        }
      })
    ));
    }

    return h('select', {
      domProps: {
        value: dataItem[field]
      },
      on: {
        change: (event) => {
          // Update the value in the data source
          dataItem[field] = event.target.value;
          this.onStatusChange(dataItem);
        }
      }
    }, 
    this.statusOptions.map(option =>
      h('option', {
        domProps: {
          value: option.value,
          innerHTML: option.text,
          selected: option.value === dataItem[field]
        }
      })
    ));
  },
  onStatusChange(item) {
    console.log('Status Changed:', item.status);
    // Add logic to update status in the backend, if required
  }
},
    DownloadFilledApp(){
        window.open(helper.SERVICEURL+"filledpdf?username="+this.username, '_blank');
    },  
    downloaddocFile(url){
      window.open(helper.SERVICEURL+"download?filename="+url, '_blank');
    },
    downloadFile() {
      var bulkArr = []
      //let data = this.SelectedItems;
      this.SelectedItems.forEach(x=>{
        bulkArr.push(x.username)
      })
      const article = {
        "username": bulkArr,
      }; 
      if(bulkArr.length>0)
      this.exportExcel(article)

      // const fileName = "Applications";
      // const exportType = exportFromJSON.types.csv;
      // if (data) exportFromJSON({ data, fileName, exportType });
    },
    
   setdob(e){
      this.DOB =moment(e,'DD-MM-YYYY')
    },
    convertdate(d){
      return moment(d).format('DD-MM-YYYY');
    },
    filterChange (event) {
      this.WorkLocationList = this.filterData(event.filter);
    },
    filterData(filter) {
        const data = this.MainWorkLocationList;
        return filterBy(data, filter);
    },
    PreffilterChange (event) {
      this.PreferredLocationList = this.PreffilterData(event.filter);
    },
    PreffilterData(filter) {
        // const data = this.MainWorkLocationList;
        const data = this.PreferredLocationMain;
        return filterBy(data, filter);
    },     
    JobfilterChange (event) {
      this.JobCategoryList = this.JobfilterData(event.filter);
    },
    JobfilterData(filter) {
        const data = this.JobCategoryListMain;
        return filterBy(data, filter);
    },    
    QualfilterChange (event) {
      this.QualificationList = this.QualfilterData(event.filter);
    },
    QualfilterData(filter) {
        const data = this.QualificationListMain;
        return filterBy(data, filter);
    },
     JobCategoryChange(event){
      if(event.value == "Other"){
        this.IsJobCategory = true
        this.JobCategory = ""
        this.Designation = ""
      }else{
        this.IsJobCategory = false
      
      console.log("####JobCategoryChange", event)
        //his.value = event.value;
        var  self = this
         self.DesignationList = []
         self.Designation=""
       const article = {"jobcategory.jobtitle":event.value};
      //  const header = {header : {SessionId: "113"}}
        this.axios.post(helper.SERVICEURL+"designation/get", article) //header
          .then(response => {
            if(response.data){
                var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.designation
                self.DesignationList.push(temp)
              })               
            }      
          });
        }
    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
				margin: 0,
  			filename: "Export PDF",
			});
    },
    reset() {      
      this.Name = "",
      this.MailId = "",
      this.PhoneNumber = "",
      this.Gender = "",
      this.WorkLocation = "",
      this.Qualification = "",
      this.Designation = "",
      this.JobCategory = "",
      this.Experience = "",
      this.JobAppliedfor = "",
      this.DOB = "",
      this.TechnicalSkillsComma = "",
      this.PreferredLocation = [],
      this.Notes = "",
      this.CtcRange = "",
      this.NotesHistory=[],
      this.EmploymentHistory = [],
      this.errorFlag = false,
      this.applicationID="",
      this.Mode="NEW",
      this.Filebase = "",
      this.filename= "",
      this.FileTXT = "",
      this.TechnicalSkills = "",
      this.Notes= "",
      this.Candidatecommands="",
      this.Isplaced = false,
      this.IsWorklocationOthers = false,
      this.IsCtcRange = false,
      this.IsQualification = false,
      this.IsExperience = false,
      this.IsJobCategory = false
    },
    validate(){
      this.errorFlag = false
      return new Promise(resolve => {
          // //eslint-disable-next-line
      const regexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (this.Name==""){
          this.errorFlag = true
          return this.error("Name is required!")
        }
        if (this.MailId==""){
          this.errorFlag = true
          return this.error("Email is required!")
        } else if (!this.MailId.match(regexp)){
          this.errorFlag = true
          return this.error("Email is not valid!")
        }
        if (this.Gender==""){
          this.errorFlag = true
          return this.error("Gender is required!")
        } 
        if (this.PhoneNumber==""){
          return this.error("Phone number is required!")
        } else if (this.PhoneNumber.length!=10){
          return this.error("Phone number is not valid!")
        }
        if (this.WorkLocation==""){
          this.errorFlag = true
          return this.error("WorkLocation is required!")
        }
        if (this.Qualification==""){
          this.errorFlag = true
          return this.error("Qualification is required!")
        }
         if (this.JobCategory==""){
          this.errorFlag = true
          return this.error("Job Category is required!")
        }
        if (this.Designation==""){
          this.errorFlag = true
          return this.error("Designation is required!")
        }
        if (this.Experience==""){
          this.errorFlag = true
          return this.error("Experience is required!")
        }
        if (this.DOB==""){
          this.errorFlag = true
          return this.error("Date of Birth is required!")
        }
        if (this.PreferredLocation == ""){
          this.errorFlag = true
          return this.error("Preferred Location is required!")
        }
        if (this.CtcRange == ""){
          this.errorFlag = true
          return this.error("CTC Range is required!")
        }
        if (this.TechnicalSkillsComma == ""){
          this.errorFlag = true
          return this.error("Technical Skills is required!")
        }
        if (this.JobAppliedfor==""){
          this.errorFlag = true
          return this.error("Job Applied for is required!")
        }
        if(this.Experience!="Fresher"){          
          this.EmploymentHistory.forEach(x=>{            
            if(x.PreOrganizationName == undefined || x.PreOrganizationName == "" ||
              x.StartDate == undefined || x.StartDate == "" ||
              x.EndDate == undefined || x.EndDate == "" ||
              x.PreDesignation == undefined || x.PreDesignation == ""){
              this.errorFlag = true
              this.failFlag = true
              return this.error("All Fields in Employment History is Required !")              
            }
            if(this.failFlag){              
              return false
            }            
          })
        }
        console.log("ErrorFlag", this.errorFlag)
        setTimeout(() => {
          if(!this.errorFlag){
            resolve (true)
          }else{
            resolve (false)
          }
          
        }, "500")
      });
    },
    error(text){
      var _this = this
      if (this.maxalert){
         this.maxalert = false
      }
       this.max = text
         this.maxalert = true
         setTimeout(function(){
                    _this.maxalert = false;
                }, 3000);
                return false;
    },
    isNumber (evt){
      const keysAllowed= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
      const keyPressed = evt.key;    
        if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault()
        }
    },
    view(n){
      this.username = n.username
      this.cstatus = n.cutomestatus
      this.viewBtn = true      
      this.loadingpdf=true
      document.getElementById('pdfIframe').src=""
      var pdfUrl = helper.SERVICEURL+"filledpdf?username="+n.username+"&preview=true";
      this.axios.get(pdfUrl) //header
      .then(response => {
        console.log("response",response)
        if(response.data.status){
          this.loadingpdf=false
          document.getElementById('pdfIframe').src = response.data.pdf;
        } else {
          this.loadingpdf=false
          swal({  
                  text: "Application Incomplete! Fill All Mandatory Fields!",
                  timer:2000, 
                  buttons: false,
                }); 
        }
      })
      
    },
    setData(d){
      console.log("------->",d)
      this.username = d.username
      this.ApplicationStatus = d.ApplicationStatus || ""
      this.AadharNo = d.AadharNo
      this.Age = d.Age
      this.AnnualIncome = d.AnnualIncome
      this.Caste = d.Caste
      this.CommunicationAddress = d.CommunicationAddress
      this.Community = d.Community
      this.DOB = d.DOB
      this.District = d.District
      this.FatherName = d.FatherName
      this.Gender = d.Gender
      this.MotherName = d.MotherName
      this.MotherTongue = d.MotherTongue
      this.Name = d.Name
      this.Nationality = d.Nationality
      this.Occupation = d.Occupation
      this.Pincode = d.Pincode
      this.Post = d.Post
      this.Religion = d.Religion
      this.State = d.State
      this.Taluk = d.Taluk
      this.WhatsAppNo = d.WhatsAppNo
      this.isotherReligion = d.isotherReligion
      this.Qualifying = d.Qualifying || []

      

      this.SSLCTamilq1=d.SSLCTamilq1
      this.SSLCTamilq2=d.SSLCTamilq2
      this.SSLCTamilq3=d.SSLCTamilq3
      this.SSLCTamilMY=d.SSLCTamilMY

      this.SSLCEnglishq1=d.SSLCEnglishq1
      this.SSLCEnglishq2=d.SSLCEnglishq2
      this.SSLCEnglishq3=d.SSLCEnglishq3
      this.SSLCEnglishMY=d.SSLCEnglishMY

      this.SSLCMathq1=d.SSLCMathq1
      this.SSLCMathq2=d.SSLCMathq2
      this.SSLCMathq3=d.SSLCMathq3
      this.SSLCMathMY=d.SSLCMathMY

      this.SSLCScienceq1=d.SSLCScienceq1
      this.SSLCScienceq2=d.SSLCScienceq2
      this.SSLCScienceq3=d.SSLCScienceq3
      this.SSLCScienceMY=d.SSLCScienceMY

      this.SSLCSocialsq1=d.SSLCSocialsq1
      this.SSLCSocialsq2=d.SSLCSocialsq2
      this.SSLCSocialsq3=d.SSLCSocialsq3
      this.SSLCSocialsMY=d.SSLCSocialsMY


      this.HSCTamilq1=d.HSCTamilq1
      this.HSCTamilq2=d.HSCTamilq2
      this.HSCTamilq3=d.HSCTamilq3
      this.HSCTamilMY=d.HSCTamilMY

      this.HSCEnglishq1=d.HSCEnglishq1
      this.HSCEnglishq2=d.HSCEnglishq2
      this.HSCEnglishq3=d.HSCEnglishq3
      this.HSCEnglishMY=d.HSCEnglishMY

      this.HSCPhysicsq1=d.HSCPhysicsq1
      this.HSCPhysicsq2=d.HSCPhysicsq2
      this.HSCPhysicsq3=d.HSCPhysicsq3
      this.HSCPhysicsMY=d.HSCPhysicsMY

      this.HSCChemistryq1=d.HSCChemistryq1
      this.HSCChemistryq2=d.HSCChemistryq2
      this.HSCChemistryq3=d.HSCChemistryq3
      this.HSCChemistryMY=d.HSCChemistryMY

      this.HSCMathq1=d.HSCMathq1
      this.HSCMathq2=d.HSCMathq2
      this.HSCMathq3=d.HSCMathq3
      this.HSCMathMY=d.HSCMathMY

      this.HSCBioq1=d.HSCBioq1
      this.HSCBioq2=d.HSCBioq2
      this.HSCBioq3=d.HSCBioq3
      this.HSCBioMY=d.HSCBioMY

      this.NameVocational=d.NameVocational

      this.HSCtq1=d.HSCtq1
      this.HSCtq2=d.HSCtq2
      this.HSCt3=d.HSCt3
      this.HSCtMY=d.HSCtMY

      this.HSCPractical1q1=d.HSCPractical1q1
      this.HSCPractical1q2=d.HSCPractical1q2
      this.HSCPractical13=d.HSCPractical13
      this.HSCPractical1MY=d.HSCPractical1MY

      this.HSCPractical2q1=d.HSCPractical2q1
      this.HSCPractical2q2=d.HSCPractical2q2
      this.HSCPractical23=d.HSCPractical23
      this.HSCPractical2MY=d.HSCPractical2MY

      this.ExtraCurricular =d.ExtraCurricular || []
      this.PhysicalIdentification1=d.PhysicalIdentification1
      this.PhysicalIdentification2=d.PhysicalIdentification2
      this.Transport=d.Transport
      this.Route=d.Route
      this.Stage=d.Stage
      this.BoardingPoint=d.BoardingPoint
      this.Hostel=d.Hostel
      this.BloodGroup=d.BloodGroup
      this.exserviceman=d.exserviceman
      this.exservicemanNo=d.exservicemanNo
      this.exservicemanRank=d.exservicemanRank
      this.firstgraduate=d.firstgraduate
      this.singleChild=d.singleChild
      this.differentlyabled=d.differentlyabled
      this.NSSYRC=d.NSSYRC

      this.Docs =d.Docs || []

      this.Place = d.Place
      this.Signatureby = d.Signatureby || ""
      this.GuardianName = d.GuardianName || ""
      this.Branch = d.Branch
      this.admittingyear = d.admittingyear
      this.ApplicantSign = d.ApplicantSign || ""
      this.ParentSign = d.ParentSign
      this.applicationID = d.applicationID
    },
    approve(n){
      console.log("n.username-->",n.username)
      var userDetails = sessionStorage.getItem("sessionDetails");
      var par = JSON.parse(userDetails)
      var sessid = par.SessionId
      var encodedparam = btoa("APPROVE||"+n.username+"||"+sessid)
      var url = window.location.protocol + '//'+window.location.hostname+(window.location.port ? ':' + window.location.port : '')+"/?Param="+encodedparam+"#/addnewapplication"
      window.open(url, '_blank');
    },
    edit(n){ 
      if(n.ApplicationStatus=='approved')
      {
        alert("Application Already Approved you can't edit.")
        return false
      }
      var userDetails = sessionStorage.getItem("sessionDetails");
      var par = JSON.parse(userDetails)
      var sessid = par.SessionId
      var encodedparam = btoa("UPDATE||"+n.username+"||"+sessid)
      var url = window.location.protocol + '//'+window.location.hostname+(window.location.port ? ':' + window.location.port : '')+"/?Param="+encodedparam+"#/addnewapplication"
      window.open(url, '_blank');
    },
    AddNewApp(){
      var userDetails = sessionStorage.getItem("sessionDetails");
      var par = JSON.parse(userDetails)
      var sessid = par.SessionId
      var encodedparam = btoa("NEW||nan"+"||"+sessid)
      var url = window.location.protocol + '//'+window.location.hostname+(window.location.port ? ':' + window.location.port : '')+"/?Param="+encodedparam+"#/addnewapplication"
      window.open(url, '_blank');
    },
    updatecrmstatus(){
      // const leadData = {
      //   "email": this.username,
      // };
      if(this.cstatus!='converted')
            return false


      const token = "aade602b2d66a03:d5358355f5d4dd3"; // Store securely!

      // First, find the lead by email
      this.axios.get(
        `https://app.muantigua.org/api/resource/CRM Lead?filters=[["email","=","${this.username}"]]`,
        {
          headers: {
            'Authorization': `token ${token}`,
            'Content-Type': 'application/json',
            'Expect': ''
          }
        }
      )
      .then(response => {
        const leads = response.data.data;

        if (leads.length > 0) {
          // Lead exists, update it
          const leadName = leads[0].name; // Get the lead's name (ID)
          const updateData = {
            "status": this.cstatus // Your new status
          };

          return this.axios.put(
            `https://app.muantigua.org/api/resource/CRM Lead/${leadName}`,
            updateData,
            {
              headers: {
                'Authorization': `token ${token}`,
                'Content-Type': 'application/json',
                'Expect': ''
              }
            }
          )
          .then(updateResponse => {
            console.log('Lead updated:', updateResponse.data);
          });
        } 
      })
      .catch(error => {
        console.error('Error:', error.response?.data || error.message);
      });
    },
    updatestatus(){
      const article = {
        "username": this.username,
        "cutomestatus":this.cstatus,
      };
      let self = this
      this.axios.post(helper.SERVICEURL+"applications/setstatus", article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
                self.getData() 
                self.viewBtn=false;
                self.updatecrmstatus()
                self.reset()
                         
            } else {
            swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
            }     
          });
    },
    del(n){
      const article = {
        "username": n.username,
      };
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        buttons: true,
        dangerMode: true,
      }).then((result) => {
      if (result) {
        this.axios.post(helper.SERVICEURL+"applications/delete", article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
                   this.getData()                  
            } else {
            swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
            }     
          });
      }
    })
       
      
    },
    delNotes(n){
      const article = {
        "applicationID": this.applicationID,
        "Note":n.Notes
      };
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        buttons: true,
        dangerMode: true,
      }).then((result) => {
      if (result) {
        this.axios.post(helper.SERVICEURL+"applications/deletenotes", article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
                   this.edit(
                    {
                      applicationID : this.applicationID
                    }
                    )                  
            } else {
            swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
            }     
          });
      }
    })
       
      
    },
    Bulkdel(){
      var bulkArr = []
      //var FilePathArr = []
      console.log("@@bulkdel", this.SelectedItems)
      this.SelectedItems.forEach(x=>{
        bulkArr.push(x.username)
      })
      const article = {
        "username": bulkArr,
      }; 
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        buttons: true,
        dangerMode: true,
      }).then((result) => {
        if (result) {
        this.axios.post(helper.SERVICEURL+"applications/bulkdelete", article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,
                }); 
                   this.getData()                  
            } else {
            swal({
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000, 
                }); 
            }     
          });
        }
      })
      
    },
    getData: function (e) {
      const that = this;
      that.loader = true;
      var article = {}
      if(e != undefined){
          article = e
      }else{
        article = {"filter":{"filters":[{"logic":"and","filters":[]}],"logic":"and"},"sort":null,"skip":0,"take":10}
      }      
      that.axios.post(helper.SERVICEURL+"applications/get", article)
      .then(response => {
        if(response.data){
          that.list= response.data.Data 
          that.total = response.data.Total;
          this.errorFlag = false
          that.gridData = that.list;
          that.loader = false;   
          that.SelectedItems = []            
        }      
      });
    },
    ActityGetData: function (e) {
      const that = this;
      that.loader = true;
      var article = {}
      if(e != undefined){
        article = {"filter":{"filters":[{"logic":"and","filters":[{"field":"applicationID","operator":"contains","value":e}]}],"logic":"and"},"sort":null,"skip":0,"take":100}        
        // article = {applicationID : e}        
      }          
      that.axios.post(helper.SERVICEURL+"activitylog/get", article)
      .then(response => {
        if(response.data){
          that.activityList= response.data.Data 
          that.activityTotal = response.data.Total;
          that.ActivtyGrid = that.activityList;
          that.loader = false;           
        }      
      });
    },
    DOBhandleChange(event){
      this.DOB = event.target.value;
    },
    genderList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"gender/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data     
              tempdata.forEach(x=>{
                let temp = x.gender
                self.GenderList.push(temp)
              })
            }      
          });
    },
    qualificationList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"qualification/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.qualification
                self.QualificationListMain.push(temp)
                self.QualificationList.push(temp)
              })              
            }      
          });
    },
    experienceList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"experience/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.experience
                self.ExperienceList.push(temp)
              })                 
            }      
          });
    },
    workLocationList(){
      var self = this;
       const article = {};       
        this.axios.post(helper.SERVICEURL+"district/get", article)
          .then(response => {
            if(response.data){             
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.district
                self.WorkLocationList.push(temp)
                self.MainWorkLocationList.push(temp)
                self.PreferredLocationList.push(temp)
                self.PreferredLocationMain.push(temp)
              })                
            }      
          });
    },
    designationList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"jobtitle/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.jobtitle
                self.JobCategoryList.push(temp)
                self.JobCategoryListMain.push(temp)
              })                
            }      
          });
    },
    handlefile(e) {
      const selectedfile= e.target.files[0];
      this.createBase64Image(selectedfile);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var fileBase64 = e.target.result;
        this.FileName = fileObject.name;
        this.file = fileBase64.split(",")[1]
      };
      reader.readAsDataURL(fileObject);
    },
    async save(flag){
      console.log("FLAG", flag)
      var ss = helper.getsitesettings()
       var isvalidationrequired = ss["IsMandatory"]
      if (isvalidationrequired) { //skip validation
          const result = await this.validate();
          console.log("this.result", result)
          if(!result){
            return
          }
      }
      var temparr = []
      if(this.TechnicalSkills.length > 0){
        this.TechnicalSkills.forEach(x=>{
          var tempstr = "" 
          tempstr = x.replace(/^\s+|\s+$/gm,'')
          temparr.push(tempstr)
        })
      }
      var userDetails = sessionStorage.getItem("sessionDetails");
      var par = JSON.parse(userDetails)
      if(this.Mode != "NEW"){
        
        var noHist = []
        if(this.NotesHistory.length != 0){
          noHist = this.NotesHistory[this.NotesHistory.length - 1].Notes
        }
        if(this.Notes != noHist){
          var not = this.Notes
          var tempNoteHitory = 
          {
            Notes : not,
            User : par.userID,
            ModifiedOn : this.TimeNow
          }         
          this.NotesHistory.push(tempNoteHitory)
        }
      }else{
        this.NotesHistory = []
      }
       const article = {
        "Gender":this.Gender,
        "Name":this.Name,
        "MailId":this.MailId,
        "PhoneNumber":this.PhoneNumber,
        "WorkLocation":this.WorkLocation,
        "PreferredLocation":this.PreferredLocation,
        "Qualification":this.Qualification,
        "Designation":this.Designation,
        "JobCategory":this.JobCategory,
        "Experience":this.Experience,
        "JobAppliedfor":this.JobAppliedfor,
        "Filebase": this.file,
        "filename":this.FileName,
        "DOB": this.DOB,        
        "Notes":this.Notes,
        "NotesHistory":this.NotesHistory,
        "EmploymentHistory" : this.EmploymentHistory,
        "CtcRange" : this.CtcRange,
        "Isplaced" : this.Isplaced,
        "IsWorklocationOthers" : this.IsWorklocationOthers,
        "IsCtcRange" : this.IsCtcRange,
        "IsQualification" : this.IsQualification,
        "IsExperience" : this.IsExperience,
        "IsJobCategory" : this.IsJobCategory,
        };
        if(this.Mode != "NEW"){
          if(this.TechSkillCnt == 0){
            var teArr = []
            if(this.arrTechData){
              this.arrTechData.forEach(x=>{
                var techstr = "" 
                techstr = x.replace(/^\s+|\s+$/gm,'')
                teArr.push(techstr)
              })
            }
            article.TechnicalSkills =  teArr
          }else{
            article.TechnicalSkills =  temparr
          }
        }else{
          article.TechnicalSkills =  temparr
        }

       var Actionurl= "applications/create"
       if (this.Mode=="UPDATE"){
         Actionurl = "applications/update"
         article.applicationID = this.applicationID 
       }
      const config = {
        headers:{
          SessionId: par.SessionId
        }
      };
        this.PageLoading = true
        this.axios.post(helper.SERVICEURL+Actionurl, article, config)
          .then(response => {
            this.PageLoading = false
            if(response.data.status){
                swal({    
                  text :  response.data.Msg,
                  buttons: false,
                  timer:2000,                    
                }); 
                // this.Mode="NEW"
                // this.Gender= ""
                // this.Name = ""
                // this.MailId = ""
                // this.PhoneNumber = ""
                // this.WorkLocation= ""
                // this.PreferredLocation= ""
                // this.Qualification= ""
                // this.Designation= ""
                // this.JobCategory="",
                // this.Experience= ""
                // this.JobAppliedfor= ""
                // this.Filebase = ""
                // this.filename= ""
                // this.FileTXT = "",
                // this.DOB = new Date()
                // this.TechnicalSkills = ""
                // this.Notes= ""
                // this.EmploymentHistory = []
                // this.CtcRange = ""
                // this.NotesHistory=[],
                // this.errorFlag = false,
                // this.PageLoading = false,                
                // this.Isplaced = false
                if (flag == "saveClose") {  // save & close
                  this.isadd=false
                  this.reset()
                  this.getData()
                } else if(flag == "saveAlone"){
                  this.Mode="UPDATE"
                } else{ // save and add
                  this.reset()
                  this.AddRange()
                }                
                                
            } else {
            swal({  
                  text: response.data.Msg,
                  buttons: false,
                  timer:2000,  
                }); 
                this.Mode="NEW"
            }     
          });

    },
    createDataState(dataState) {
      if(dataState != undefined){
        if(dataState.skip != 0 && dataState.filter == undefined){
          var sk = dataState.skip
          this.getData({"filter":{"filters":[{"logic":"and","filters":[]}],"logic":"and"},"sort":null,"skip":sk,"take":10})
        }
        else if(dataState.skip == 0 && dataState.filter == null){
          this.getData({"filter":{"filters":[{"logic":"and","filters":[]}],"logic":"and"},"sort":null,"skip":0,"take":10})
        }
        else if(dataState != undefined){
          this.getData(dataState)
        }
        else{
          this.getData()
        }
      }      
      this.dataState = dataState;
    },
    dataStateChange(e) {
      if (e.event) {
        let isColumnActive = filterGroupByField(e.event.field, e.data.filter);
        let changedColumn = this.columns.find(
          (column) => column.field === e.event.field
        );
        if (changedColumn) {
          changedColumn.headerClassName = isColumnActive
            ? 'customMenu active'
            : '';
        }
      }
      this.createDataState(e.data);
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
    download(n){
      window.open(helper.SERVICEURL+"downloaddocs?username="+n.username, '_blank');
    },
    RemoveRange(i) {
      var self = this;
      if (self.EmploymentHistory.length == 0) {
        return;
      }
      if (i == undefined) {
        i = self.EmploymentHistory.length - 1;
      }
      self.EmploymentHistory.splice(i, 1);
    },
    AddRange() {
      var condcheck=true      
      if (condcheck){
        if (this.EmploymentHistory.length == 0) {
          var tmp = {            
          };
          this.EmploymentHistory.push(tmp);
        } else {
          tmp = {            
          };
          this.EmploymentHistory.push(tmp);
        }
      }
    },
    onHeaderSelectionChange (event) {     
      let checked = event.event.target.checked;
      this.gridData = this.gridData.map((item) => { return {...item, selected: checked} });
    },
    onSelectionChange (event) {
        event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
        var data = event.dataItems
        if(data.length > 0 ){
          this.SelectedItems = []
          data.forEach(x=>{
            if(x.selected){
              this.SelectedItems.push(x)            
              return              
            }
          }) 
        }
    },
    onRowClick (event) {
        event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },    
    exportExcel (ar) {
      const that = this;
      that.loader = true;
      var article = {}
      if (ar){
        article = ar
      }
          
      that.axios.post(helper.SERVICEURL+"applications/get", article)
      .then(response => {
        if(response.data){
          var AllList= response.data.Data 
      //     console.log("AllList---->",AllList,Array.isArray(AllList))  
      //     const fileName = "Applications";
      // const exportType = exportFromJSON.types.csv;
          var keys = []
          var titles = []
      if (AllList.length>0) {
        keys = Object.keys(AllList[0]);
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        var t = {
          title: element,
          // locked:true,
          width : "150px",
          field: element,
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        }
        titles.push(t)
      }
       
      }
          saveExcel({
            data: AllList,
            fileName: "Application",
            columns:titles
          });        
        }      
      });      
    },
    GlobalSearchAction(){ 
      const that = this;
      that.loader = true;
      var article = {}
      if(this.GlobalSearch != ""){
        article = {"filter":{"filters":[{"logic":"or","filters":[
          {"field":"Name","operator":"contains","value":this.GlobalSearch},
          {"field":"Branch","operator":"contains","value":this.GlobalSearch},
          {"field":"Place","operator":"contains","value":this.GlobalSearch},
          {"field":"Gender","operator":"contains","value":this.GlobalSearch},
          {"field":"Caste","operator":"contains","value":this.GlobalSearch},
          ]}],"logic":"and"},"sort":null,"skip":0,"take":10}
          var intglobalsearch = parseInt(this.GlobalSearch)
          if (typeof intglobalsearch =="number"){
            article.filter.filters[0].filters.push(  {"field":"username","operator":"eq","value":parseInt(this.GlobalSearch)})
            article.filter.filters[0].filters.push(  {"field":"AadharNo","operator":"eq","value":parseInt(this.GlobalSearch)})
            article.filter.filters[0].filters.push(  {"field":"AnnualIncome","operator":"eq","value":parseInt(this.GlobalSearch)})
            article.filter.filters[0].filters.push(  {"field":"applicationID","operator":"eq","value":parseInt(this.GlobalSearch)})
          }
      }      
      that.axios.post(helper.SERVICEURL+"applications/get", article)
      .then(response => {
        if(response.data){              
          that.list= response.data.Data 
          that.total = response.data.Total;
          that.gridData = that.list
          that.loader = false;                                           
        }      
      });
    },
    GlobalSearchClear(){
      this.GlobalSearch = ""
      this.getData();    
    },
    formateDate(e){
      if(e != undefined && (this.viewBtn || this.Mode!='NEW')){ 
          if(e.indexOf("T") > 0){
          return e.split("T")[0]
          }else{
            return ""
          }              
      }else{
        return ""
      }
    },
    cellFunction: function (h, trElement , defaultSlots, props ) {
      const available = props.dataItem.Isplaced;
      const green = {
          backgroundColor: "rgb(55, 180, 0,0.32)",
      };
      const red = {
          backgroundColor: "",
      };
      const trProps = {
          style: available ? green : red,
      };

      return h(
          'tr',
          trProps,
          defaultSlots);
    },
    ctcchange(e){
      if(e.value == "Other"){
        this.CtcRange = ""
        this.IsCtcRange=true
      }else{
        this.IsCtcRange=false
      }
    },
    workLocchange(e){
      if(e.value == "Other"){
        this.IsWorklocationOthers = true
        this.WorkLocation = ""
      }else{
        this.IsWorklocationOthers = false
      }
    },
    QualChange(e){
      if(e.value == "Other"){
        this.IsQualification = true
        this.Qualification = ""
      }else{
        this.IsQualification = false
      }
    },
    ExepChange(e){
      if(e.value == "Other"){
        this.IsExperience = true
        this.Experience = ""
      }else{
        this.IsExperience = false
      }
    }

  }  
};
</script>

<style>
th.k-header.customMenu > div > div > span.k-i-more-vertical::before,
th.k-header.customMenu.active > div > div > span.k-i-more-vertical::before {
  content: '\e129';
}
.k-columnmenu-item {
  display: none;
}
th.k-header.active > div > div {
  color: #fff;
  background-color: #ff6358;
}
.grid{
  margin: 30px;
}
.top-navbar{
  margin: 15px;
}

.loader--ripple {
    display: inline-block;
    position: relative;
    height: 60px;
    width: 85px;
}

.loader--ripple div {
    position: absolute;
    animation: loader--ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: 4px solid;
    border-radius: 50%;
    color:#3297fa;
    opacity: 1;
}

.loader--ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loader--ripple {
    0% {
        top: 28px;
        left: 28px;
        opacity: 1;
        height: 0;
        width: 0;
    }

    100% {
        top: -1px;
        left: -1px;
        opacity: 0;
        height: 58px;
        width: 58px;
    }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-height: 600px;
  overflow: auto;
  width: 900px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.msg { padding: 10px; background: #222; position: relative; }
.msg h1 { color: #fff;  }
.msg a { margin-left: 20px; background: #408814; color: white; padding: 4px 8px; text-decoration: none; }
.msg a:hover { background: #266400; }
body { font-family: Georgia; color: #444; }
#inner { padding: 10px; background: #f5f5f5; border: solid #666; border-width: 8px 0 2px 0; }
.yui-gf { margin-bottom: 5px; padding-bottom: 5px; border-bottom: 1px solid #ccc; }
#hd { padding-bottom: 5px; border-bottom: 1px solid #ccc }
#hd h2 { text-transform: uppercase; letter-spacing: 2px; }
#hd h3 { text-transform: uppercase; }
#bd, #ft { margin-bottom: 5px; }
#ft { padding: 1em 0 5em 0; font-size: 92%; border-top: 1px solid #ccc; text-align: center; }
#ft p { margin-bottom: 0; text-align: center;   }
#hd h1 { font-size: 35px; font-family:sans-serif; text-transform: uppercase;}
h2 { font-size: 152% }
h3, h4 { font-size: 122%; }
h1, h2, h3, h4 { color: #333; }
p { font-size: 100%; line-height: 18px; padding-right: 3em; }
a { color: #990003 }
a:hover { text-decoration: none; }
strong { font-weight: bold; }
li { line-height: 24px; border-bottom: 1px solid #ccc; }
p.enlarge { font-size: 144%; padding-right: 6.5em; line-height: 24px; }
p.enlarge span { color: #000 }
.contact-info { margin-top: 7px; }
.last { border-bottom: 0 }
a#pdf { display: block; float: left; background: #666; color: white; padding: 6px 50px 6px 12px; margin-bottom: 6px; text-decoration: none;  }
a#pdf:hover { background: #222; }
.job { position: relative; margin-bottom: 1em; padding-bottom: 1em; border-bottom: 1px solid #ccc; }
.job h4 { position: absolute; top: 0.35em; right: 0 }
.job p { margin: 0.75em 0 3em 0; }
.last { border: none; }
.skills-list ul { margin: 0; }
.skills-list li { margin: 3px 0; padding: 3px 0; }
.skills-list li span { font-size: 152%; display: block; margin-bottom: -2px; padding: 0 }
.talent { width: 32%; float: left }
.talent h2 { margin-bottom: 6px; }
#srt-ttab { margin-bottom: 100px; text-align: center;  }
#srt-ttab img.last { margin-top: 20px }
.yui-gf .yui-u{width:80.2%;}
.yui-gf div.first{width:12.3%;}

.is-active{
    position: absolute;
    top: 6px;
    /* height: 100%; */
    right: 10px;
    font-size: 16px;
    /* background-color: #f5365c; */
    /* border-color: #f5365c; */
    /* border-radius:72px; */
    border: none;
}

</style>
